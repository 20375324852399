//queries
@media (max-width : 991px) {
  .content {
    max-width: 100vw !important;
    width    : 100vw !important;
  }
}

@media(max-width : 767px) {
  .content {
    padding: 45px 10px !important;
  }


  .profile {
    h6 {
      line-height: inherit !important;
    }
  }

  .projecten {
    .project {
      margin-top: 10px;

      .project-title,
      .project-image {
        height: 200px;
      }

      .project-title {
        h2 {
          padding: 20% 0 0 10px;
        }

        section {
          visibility: visible;
          opacity   : 1;
        }
      }
    }
  }
}