// ==========[ GENERAL ]==========

body {
  margin          : 0;
  padding         : 0;
  background-color: $white;
  font-family     : $font;
  overflow        : hidden;
}

*:active,
*:focus {
  outline: none !important;
}

// ==========[ TEXT ]==========

h1,
h2,
h3,
h4,
h5,
h6,
p {
  width  : 100%;
  margin : 0;
  padding: 0;
}

h1 {
  color      : $blue-dark;
  font-size  : 36px;
  font-weight: 500;
}

h2 {
  color      : $blue-dark;
  font-size  : 30px;
  font-weight: 500;
}

h3 {
  color      : $blue-dark;
  font-size  : 24px;
  font-weight: 500;
}

h4 {
  color      : $blue-dark;
  font-size  : 18px;
  font-weight: 500;
}

h5 {
  color      : $blue-dark;
  font-size  : 18px;
  font-weight: 500;
}

h6 {
  color      : $blue-dark;
  font-size  : 15px;
  font-weight: 500;
}

a {
  color: $pink;

  &:hover {
    color          : $pink-dark;
    text-decoration: none;
  }
}

p {
  color      : $title-color;
  font-size  : 15px;
  font-weight: 500;
  line-height: 26px;

  a {
    color: $primary;

    &:hover {
      color: $primary-dark;
    }
  }
}


blockquote {
  padding-left: 15px;
  border-left : 5px solid $bg-color-dark;

  p {
    color      : $blue-dark;
    font-weight: 600;
  }

  cite {
    display   : block;
    margin-top: 15px;

    a,
    p {
      color: $text-color;
    }
  }
}

dl {
  dt {
    font-size  : 15px;
    font-weight: 600;
  }

  dd {
    color    : $title-color;
    font-size: 14px;
  }
}

ol,
ul {
  color: $primary;

  li {
    margin-bottom: 10px;
    color        : $text-color;
    font-size    : 15px;
    font-weight  : 500;
    line-height  : 26px;

    a {
      color: $primary;

      &:hover {
        color: $primary-dark;
      }
    }
  }
}

code,
pre {
  padding         : 5px;
  background-color: $bg-color;
}

// ==========[ FORMS ]==========

.form-group {
  label {
    color      : $text-color-light;
    font-size  : 13px;
    font-weight: 300;
  }

  input.form-control {
    padding      : 10px 0;
    color        : $blue-dark;
    border       : 0;
    border-bottom: 1px solid $bg-color-dark;
    border-radius: 0;
    font-size    : 14px;
    font-weight  : 400;

    &::placeholder {
      color: $bg-color-darkest;
    }

    &:focus {
      color        : $primary;
      border-bottom: 1px solid $primary;
      outline      : 0;
      box-shadow   : none;
    }
  }

  input.fout {
    border-bottom: 1px solid red;

    &:focus {
      color        : $primary;
      border-bottom: 1px solid red;
    }
  }

  input.pickadate {
    background: none;
  }

  .form-search {
    @include cubicTransition(300ms);
    width         : 100%;
    padding-top   : 12px;
    padding-right : 16px;
    padding-bottom: 12px;
    padding-left  : 16px;
    color         : $blue-dark;
    border        : 2px $bg-color-dark solid;
    font-size     : 14px;
    font-weight   : 400;

    &::placeholder {
      color: $bg-color-darkest;
    }

    &:focus {
      border: 2px $primary solid;
    }
  }
}

// ==========[ DEFAULT TABLE ]==========

table {
  width: 100%;

  thead {
    tr {

      td,
      th {
        padding      : 14px 0 20px 0;
        color        : $title-color;
        border-bottom: 2px solid $bg-color-dark;
        font-size    : 14px;
        font-weight  : 600;
      }
    }
  }

  tbody {
    tr {
      td {
        padding      : 20px 0;
        color        : $text-color;
        border-bottom: 1px solid $bg-color-dark;
        font-size    : 14px;
        font-weight  : 500;
      }

      &:last-child {
        td {
          border-bottom: 0px;
        }
      }
    }
  }

  tfoot {
    tr {

      td,
      th {
        padding    : 20px 0 14px 0;
        color      : $title-color;
        border-top : 2px solid $bg-color-dark;
        font-size  : 14px;
        font-weight: 600;
      }
    }
  }

  caption {
    padding         : 8px 12px;
    color           : $text-color;
    background-color: $bg-color;
    font-size       : 14px;
    font-style      : italic;
  }
}

// ==========[ CUSTOM TABLE ]==========

table.custom-table {
  table-layout: fixed;

  thead {
    tr {
      th {
        color        : $title-color;
        border-bottom: 2px solid $bg-color;
        font-size    : 14px;
        font-weight  : bold;

        a {
          color: $primary;
        }
      }
    }
  }

  tbody {
    tr {
      &[data-href] {
        &:hover {
          @include softShadow;
          background-color: $bg-color;
          cursor          : pointer;

          h6 {
            color: $primary;
          }
        }
      }

      td {
        padding      : 10px 0;
        overflow-x   : hidden;
        border-bottom: 2px solid $bg-color;


        &.actions {
          display        : flex;
          align-items    : center;
          justify-content: flex-end;

          a {
            @include cubicTransition(200ms);
            display         : flex;
            width           : 40px;
            height          : 40px;
            margin-left     : 6px;
            border          : 2px solid $bg-color;
            border-radius   : 999px;
            background-color: $bg-color;
            align-items     : center;
            justify-content : center;

            i,
            svg {
              @include cubicTransition(200ms);
              color    : $bg-color-darkest;
              font-size: 16px;
            }

            &:hover {
              color           : $white;
              border          : 2px $primary-dark solid;
              background-color: $primary;

              i,
              svg {
                color: $white;
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

// ==========[ MODALS ]==========

.modal {
  .modal-content {
    @include softShadow;
    border          : 0;
    border-radius   : 4px;
    background-color: $white;
    box-shadow      : 0px 10px 50px -10px rgba(0, 0, 0, 0.2);

    .modal-header {
      border-bottom   : 0;
      border-radius   : 4px 4px 0 0;
      background-color: $primary;

      .modal-title {
        color         : $white;
        font-size     : 20px;
        font-weight   : 700;
        letter-spacing: 1px;
      }

      .close {
        color: $white;
      }
    }
  }
}

// ==========[ MAIN LAYOUT ]==========

.main-wrapper {
  display        : flex;
  width          : 100vw;
  height         : 100vh;
  align-items    : flex-start;
  flex-flow      : column;
  justify-content: flex-start;

  .top-nav {
    display         : flex;
    width           : 100%;
    min-height      : 70px;
    padding         : 17px;
    padding         : 0 25px;
    border-bottom   : 1px solid $grey;
    background-color: $white;
    align-items     : center;
    justify-content : space-between;

    .logo {
      max-height: 50px;
    }

    .left,
    .right {
      display        : flex;
      height         : 70px;
      align-items    : center;
      justify-content: flex-end;

      form {
        display        : flex;
        height         : 100%;
        align-items    : center;
        justify-content: center;

        .zoeken {
          padding            : 5px 0 5px 35px;
          color              : $text-color;
          border             : 0;
          background-image   : url(../images/search.png);
          background-repeat  : no-repeat;
          background-position: left;
          background-size    : auto 100%;
          font-size          : 15px;
          font-weight        : 500;

          &:active,
          &:focus {
            outline   : none;
            box-shadow: none;
          }
        }
      }

      .top-nav-item {
        display        : flex;
        height         : 69px;
        padding        : 0 25px;
        align-items    : center;
        justify-content: center;

        &:hover {
          background-color: lighten($grey, 10%);
        }
      }

      .inbox {
        .bell {
          width           : 23px;
          height          : 32px;
          margin-right    : -13px;
          background-image: url(../images/alert.png);
        }

        &:hover {
          text-decoration: none !important;

          .bell {
            background-image: url(../images/alert-active.png);
          }
        }
      }

      .profile {
        .profile-picture {
          display        : flex;
          justify-content: baseline;
          width          : auto !important;

          h6 {
            height     : 100%;
            margin-left: 15px;
            color      : $pink;
            line-height: 35px;
          }

          &:hover {}
        }
      }
    }

    .left {
      .top-nav-item {
        border: 0;

        .action {
          flex: 1 0 auto;
        }

        .action-timer {
          border          : 0;
          background-color: $red;

          i,
          svg {
            color: darken($red, 35%);
          }
        }

        p {
          transition: .3s ease;
        }

        &:hover {
          background-color: $white;
          text-decoration : none;

          p {
            color          : $blue-dark;
            text-decoration: none !important;
          }

          .action-timer {
            background-color: darken($red, 15%);

            i,
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }

  .page {
    display        : flex;
    width          : 100%;
    height         : calc(100vh - 70px);
    align-items    : stretch;
    justify-content: flex-start;

    .sidebar {
      display         : flex;
      height          : calc(100vh - 70px);
      border-right    : 1px solid $grey;
      background-color: $white;
      align-items     : center;
      flex            : 0 1 70px;
      flex-flow       : column;
      justify-content : space-between;

      .bottom,
      .top {
        width: 100%;
      }

      &.closed {
        .sidebar-item {
          display        : flex;
          width          : 100%;
          border-right   : 3px solid transparentize($primary, 1);
          align-items    : center;
          justify-content: left;
          padding        : 20px 30px;
          color          : $title-color;
          font-size      : 20px;

          i,
          svg {
            color    : $text-color;
            font-size: 24px;
          }

          p {
            display: none;
          }

          .active-icon {
            display: none;
          }

          .inactive-icon {
            display: block;
          }

          .active-icon,
          .inactive-icon {
            max-width: 25px;
          }

          &.active {
            background-color: lighten($grey, 10%);
            text-decoration : none;
            color           : $pink;
          }

          &:hover {
            background-color: lighten($grey, 10%);
            text-decoration : none;
            color           : $pink;

            .inactive-icon {
              display: none;
            }

            .active-icon {
              display: block;
            }

            i,
            svg {
              color: $primary;
            }
          }
        }
      }
    }

    .content {
      width     : calc(100vw - 70px);
      max-width : calc(100vw - 70px);
      min-height: 100%;
      padding   : 40px 45px;
      overflow-y: scroll;
    }
  }
}

// ==========[ LOGIN ]==========

.login-wrapper {
  display            : flex;
  width              : 100vw;
  height             : 100vh;
  background-image   : url('../images/login-background.png');
  background-repeat  : no-repeat;
  background-position: center;
  background-size    : 55%;
  align-items        : center;
  justify-content    : center;

  .login-title {
    color      : $title-color;
    font-size  : 28px;
    font-weight: 700;
  }
}

// ==========[ DASHBOARD: INDEX ]==========

.dashboard-index-block {
  @include softShadow;
  display         : block;
  position        : relative;
  width           : 100%;
  padding-top     : 100%;
  border-radius   : 4px;
  background-color: $white;
  transition      : all 350ms cubic-bezier(0.645, 0.045, 0.355, 1.000);

  .inner {
    display        : flex;
    position       : absolute;
    top            : 0;
    right          : 0;
    bottom         : 0;
    left           : 0;
    padding        : 25px;
    flex-direction : column;
    justify-content: center;

    h1 {
      color         : $primary;
      font-size     : 52px;
      font-weight   : 900;
      letter-spacing: .5px;
      transition    : all 350ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }

    h4 {
      color     : $text-color;
      transition: all 350ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
  }

  &::before {
    @include niceGradientLeftDown;
    z-index      : -1;
    content      : "";
    position     : absolute;
    top          : 0;
    right        : 0;
    bottom       : 0;
    left         : 0;
    opacity      : 0;
    border-radius: 4px;
    transition   : all 350ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
  }

  &:hover {
    box-shadow: 0 3rem 5rem -2rem $shadow-color-dark !important;
    transform : scale(1.02);

    h1,
    h4 {
      color: $white;
    }

    &::before {
      opacity: 1;
    }
  }
}

// ==========[ TASKS ]==========

.tasks {
  .task-header {
    display       : flex;
    width         : 100%;
    margin-top    : 40px;
    padding-bottom: 18px;
    border-bottom : 2px solid $blue;
    align-items   : center;
    cursor        : pointer;

    h4 {
      color: $title-color;
    }

    &:hover {
      h4 {
        color: $blue-dark;
      }
    }
  }

  .task-category {
    .task {
      display        : flex;
      min-height     : 62px;
      padding        : 14px 0;
      border-bottom  : 2px solid $bg-color;
      align-items    : center;
      justify-content: space-between;

      .task-name {
        color           : $title-color;
        border          : 0;
        background-color: transparent;
        font-size       : 15px;
        font-weight     : 500;
        flex            : 2 0 auto;

        &:active,
        &:focus {
          color: $blue-dark;
        }

        &::placeholder {
          color: $bg-color-darkest;
        }
      }

      &:hover {
        background-color: $bg-color;
      }

      .attachment {
        color    : $text-color;
        font-size: 16px;

        &:hover {
          color          : $blue-dark;
          text-decoration: none;
        }
      }

      .task-priority {
        width        : 15px;
        height       : 15px;
        border-radius: 999px;

        &.none {
          border: 2px solid $bg-color-dark;

          &:hover {
            background-color: $bg-color-dark;
          }
        }

        &.low {
          border          : 2px solid darken($yellow, 10%);
          background-color: $yellow;
        }

        &.high {
          border          : 2px solid darken($red, 10%);
          background-color: $red;
        }
      }

      .left,
      .right {
        display    : flex;
        align-items: center;
        flex       : 1 0 auto;
      }

      .right {
        justify-content: flex-end;
      }

      &.done {
        opacity: .4;
      }

      &.new {
        .right {
          display: none;
        }
      }
    }

    .selected {
      background-color: $bg-color;
    }
  }
}

// ==========[ TODOS ]==========

.todos {
  display       : flex;
  align-content : flex-start;
  flex-direction: column;

  .todo {
    display        : flex;
    padding        : 10px 0;
    border-bottom  : 2px solid $bg-color;
    align-items    : center;
    justify-content: space-between;

    &:last-child {
      border-bottom: 0;
    }
  }
}

// ==========[ FULLSCREEN OVERLAY ]==========

.dark-full-overlay {
  z-index         : 100;
  display         : none;
  position        : fixed;
  top             : 0;
  right           : 0;
  bottom          : 0;
  left            : 0;
  background-color: transparentize($blue-dark, .8);
}