// ==========[ BUTTONS ]==========
.btn {
    padding      : 12px 28px;
    border-radius: 2px;
    font-family  : $font;
    font-size    : 14px;
}

.btn-small {
    padding: 6px 9px;
}

.btn-primary {
    color           : $white;
    border          : 1px solid $pink;
    background-color: $pink;
    font-weight     : bold;

    &:active,
    &:focus,
    &:hover {
        color           : $white;
        border          : 1px solid $pink-dark;
        background-color: $pink-dark;
    }

    &.active,
    &.disabled {
        color           : $white !important;
        border          : 1px solid $primary-dark !important;
        background-color: $primary-dark !important;
    }
}

.btn-secondary {
    color           : $bg-color-darkest;
    border          : 1px solid $bg-color-darker;
    background-color: transparent;

    &:hover {
        color           : $white;
        border          : 1px solid $bg-color-darkest;
        background-color: $bg-color-darkest;
    }
}

.btn-full-width {
    width           : 100%;
    color           : $text-color;
    border-radius   : 6px;
    background-color: $bg-color;
    font-size       : 14px;
    font-weight     : 600;

    &:hover {
        color           : $white !important;
        background-color: $bg-color-darker !important;
        text-decoration : none;
    }
}

.btn-dropdown {
    padding         : 6px 10px;
    color           : $bg-color-dark;
    border-radius   : 2px;
    background-color: transparent;
    font-size       : 18px;

    &:hover {
        color: $bg-color-darkest;
    }

    &:focus {
        outline   : 0;
        box-shadow: none;
    }
}

.btn-group {
    border: 0;
}

.alphabet-buttons {
    display        : flex;
    align-items    : center;
    flex-wrap      : wrap;
    justify-content: flex-start;

    a {
        display         : flex;
        width           : 40px;
        height          : 40px;
        margin-right    : 5px;
        color           : $bg-color-darkest;
        border-radius   : 4px;
        background-color: $bg-color;
        align-items     : center;
        font-size       : 16px;
        font-weight     : bold;
        flex            : 1 0 40px;
        justify-content : center;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color           : $bg-color;
            background-color: $bg-color-darkest;
            text-decoration : none;
        }

        &.active {
            color           : $white;
            background-color: $primary;

            &:hover {
                background-color: $primary-dark;
            }
        }
    }
}

// ==========[ HELPER CLASSES ]==========
.shadow {
    box-shadow: -10px 9px 21px 0 $shadow-color !important;
}

.rounded {
    border-radius: 999px !important;
}

.icon-small {
    max-width: 16px;
}

.icon-medium {
    max-width: 22px;
}

.icon-large {
    max-width: 32px;
}

.success {
    color: $green;
}

.warning {
    color: $yellow;
}

.danger {
    color: $red;
}

.success-bg {
    background-color: $green;
}

.warning-bg {
    background-color: $yellow;
}

.danger-bg {
    background-color: $red;
}

.nice-transition {
    transition: all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

.disable-select {
    user-select: none;
}

.searchdd {
    position: relative;

    #search-dropdown {
        margin-top: 25px;
    }
}

.form-group-flex {
    flex: 1 0 50px;
}

.flex-fixed-width {
    display  : block;
    min-width: 250px;
    flex     : 2 0 250px !important;
}

// ==========[ ALERTS ]==========
.alert {
    padding      : 18px 27px;
    border-radius: 2px;
    font-size    : 14px;
}

.alert-danger {
    color           : $white;
    border          : 1px solid $red;
    background-color: $red;
    font-weight     : 500;
}

// ==========[ DROPDOWNS ]==========
.dropdown-menu {
    @include softShadow();
    max-height   : 250px;
    padding      : 18px 0;
    overflow-y   : scroll;
    border       : 1px solid $bg-color-darker;
    border-radius: 2px;

    .dropdown-item {
        padding    : 7px 32px;
        color      : $title-color;
        font-size  : 15px;
        font-weight: 500;

        &.selected,
        &:active {
            color           : $white;
            background-color: $pink;
        }
    }

    .dropdown-header {
        padding    : 7px 32px;
        color      : $pink;
        font-size  : 17px;
        font-weight: 500;
    }

    .top-nav-item {
        display: none !important;
    }

    &.dropdown-large {
        padding: 40px;
    }
}

ul.dropdown-menu {
    padding: 0 !important;

    li {
        margin: 0;

        .dropdown-item {
            padding: 10px 22px;
        }
    }
}

.dropdown-notifications {
    .dropdown-item {
        display    : flex;
        padding    : 8px 35px;
        align-items: center;

        p {
            margin: 0;

            b {
                color      : $primary;
                font-size  : 15px;
                font-weight: 600;
            }
        }
    }

    .dropdown-header {
        padding: 8px 35px;

        h5 {
            margin: 0;
        }

        a {
            display    : flex;
            color      : $text-color;
            align-items: center;
            font-size  : 15px;
            font-weight: 500;

            &:hover {
                color: $primary;
            }
        }
    }
}

// ==========[ ISOTOPE GRID ]==========
.isotope-grid {
    width: 100%;

    .grid-item,
    .grid-sizer {
        width        : 316px;
        display      : inline-block;
        margin-right : 10px;
        margin-bottom: 10px;
        transition   : transform 0.5s;

        img {
            width : 316px;
            height: auto;
        }

        &:hover {
            z-index        : 4;
            text-decoration: none;
            transform      : scale(1.2);
        }

        .overlay {
            background-color: $pink;
            padding         : 5px 10px;
            display         : block;

            p {
                color: $white;
            }
        }
    }

    .no-hover {
        &:hover {
            z-index        : 0;
            text-decoration: none;
            transform      : none;
        }
    }

    .grid-item--width2 {
        width: calc(50% - 30px);
    }
}

.isotope-grid-half {
    width: 100%;

    .grid-item,
    .grid-sizer {
        width        : calc(50% - 16px);
        margin-bottom: 30px;
    }

    .grid-item--width2 {
        width: 100%;
    }
}

.project-slider {
    height  : 60vh;
    overflow: hidden;
}

.download-pdf-form {
    float: right;

    .download-pdf {
        margin-left     : 20px;
        background-color: $title-color;
        border          : none;
        color           : $white;
        text-align      : center;
        text-decoration : none;
        display         : inline-block;
        font-size       : 35px;
        border-radius   : 50%;
        height          : 70px;
        width           : 70px;

        &:hover {
            text-decoration : none;
            background-color: $pink;
            cursor          : pointer;
        }
    }
}

// ==========[ PROJECTEN ]==========
.scroll-arrow {
    z-index        : 9999;
    display        : flex;
    position       : absolute;
    top            : 100px;
    right          : 100px;
    width          : 60px;
    height         : 60px;
    align-items    : center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

.rotated {
    -webkit-transform: rotate(180deg);
    -moz-transform   : rotate(180deg);
    -ms-transform    : rotate(180deg);
    -o-transform     : rotate(180deg);
    transform        : rotate(180deg);
}

.projecten {
    .project {

        .project-image,
        .project-title {
            height : 350px;
            display: block;

            &:hover {
                a {
                    text-decoration: none;
                }

                h2 {
                    color          : $pink;
                    text-decoration: none;
                    font-size      : 25px;
                }

                section {
                    visibility: visible;
                    opacity   : 1;
                }
            }
        }

        .hovered {
            .project-title {
                a {
                    text-decoration: none;
                }

                h2 {
                    color          : $pink;
                    text-decoration: none;
                    font-size      : 25px;
                }

                section {
                    visibility: visible;
                    opacity   : 1;
                }
            }
        }

        .project-title {
            background-color: $bg-color;
            padding         : 15px;

            section {
                visibility: hidden;
                opacity   : 0;
                transition: visibility 0s, opacity 0.5s linear;

                p {
                    padding: 10px;
                }
            }

            h2 {
                padding  : 50% 0 0 10px;
                font-size: 25px;
            }
        }

        .project-image {
            background-repeat  : no-repeat;
            background-size    : cover;
            background-position: center;
        }

        // &:hover {
        //   text-decoration: none;
        //   section {
        //     visibility: visible;
        //     opacity   : 1;
        //   }
        //   h2 {
        //     color: $pink;
        //   }
        // }
    }
}

// ==========[ MOBILE SLIDER ]==========
.mobile-slider {
    overflow: hidden;

    .mobile-slider-slides {
        margin-left: 40px;
    }

    .slider-controls-wrapper {
        position  : absolute;
        top       : 80px;
        right     : 0;
        bottom    : 0;
        left      : 0;
        min-height: 40px;
        max-height: 40px;

        .slider-controls {
            display        : flex;
            min-height     : 100%;
            align-items    : center;
            justify-content: space-between;

            .previous-slide {
                margin-left: 15px;
            }

            .next-slide {
                margin-right: 15px;
            }

            a {
                z-index         : 1;
                display         : flex;
                width           : 40px;
                height          : 40px;
                color           : $white;
                background-color: $pink;
                align-items     : center;
                justify-content : center;
                transition      : all 400ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

                &:hover {
                    background-color: $title-color;
                    text-decoration : none;
                }
            }
        }
    }

    .mobile-slider-slides {
        min-height: 600px;
    }
}

// ==========[ MOBILE NAV ]==========
.go-back {
    color: $title-color;

    &:hover {
        color          : $pink;
        text-decoration: none;
    }
}

// ==========[ MOBILE NAV ]==========
.off-canvas-nav {
    z-index        : 9999;
    z-index        : 2;
    display        : flex;
    position       : fixed;
    top            : 0;
    left           : -520px;
    bottom         : 0;
    width          : 520px;
    max-width      : 80vw;
    padding        : 40px;
    border-left    : 2px solid $grey;
    background     : $white;
    align-items    : flex-start;
    flex-direction : column;
    justify-content: space-between;
    transition     : left 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

    section {
        display        : flex;
        width          : 100%;
        align-items    : flex-start;
        flex-direction : column;
        justify-content: flex-start;

        .burger-menu {
            align-self: flex-end;
        }
    }

    ul {
        margin : 0;
        padding: 0;

        li {
            margin    : 20px 0;
            list-style: none;

            a {
                color      : $title-color;
                font-size  : 16px;
                font-weight: 700;

                &.active {
                    color      : $pink;
                    font-weight: 700;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &.active {
        display   : flex;
        left      : 0;
        box-shadow: -10px 0px 80px 0px rgba(0, 0, 0, 0.25);
    }
}

.spacer {
    height: 50px;
}

.user-form {
    background-color: $white;
    padding         : 30px;
}