// ==========[ FONTS ]==========

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
$font             : "Lato",
sans-serif;

// ==========[ COLOR VARIABLES ]==========

$white             : #FFFFFF;
$bg-color          : #F5F6FA;
$bg-color-dark     : darken($bg-color, 5%);
$bg-color-darker   : darken($bg-color, 10%);
$bg-color-darkest  : darken($bg-color, 20%);
$shadow-color      : transparentize(#8098D5, .93);
$shadow-color-dark : transparentize(#8098D5, .7);
$title-color       : #1a171b;
$text-color        : transparentize(#d6d6d6, .2);
$text-color-light  : transparentize(#d6d6d6, .4);

$grey       : #E1E1E1;
$grey-dark  : #dae0e5;
$blue-light : #ADB5D5;
$blue       : #C9CDDA;
$blue-dark  : #14233E;

$primary      : #18BBB9;
$primary-dark : darken(#18BBB9, 10%);
$red          : #EF8888;
$yellow       : #EED68A;
$green        : #C0E281;
$pink         : #E6007E;
$pink-dark    : darken($pink, 15%);

// ==========[ USERS COLORS ]==========

$user-yellow      : #f9ca24;
$user-yellow-dark : darken($user-yellow, 20%);

$user-orange      : #f0932b;
$user-orange-dark : darken($user-orange, 20%);

$user-red      : #eb4d4b;
$user-red-dark : darken($user-red, 20%);

$user-green      : #badc58;
$user-green-dark : darken($user-green, 25%);

$user-light-blue      : #c7ecee;
$user-light-blue-dark : darken($user-light-blue, 20%);

$user-blue      : #7ed6df;
$user-blue-dark : darken($user-blue, 25%);

$user-dark-blue      : #686de0;
$user-dark-blue-dark : darken($user-dark-blue, 20%);

$user-pink      : #e056fd;
$user-pink-dark : darken($user-pink, 30%);

// ==========[ MIXINS ]==========

@mixin softShadow {
  box-shadow: -10px 9px 21px 0 $shadow-color !important;
}

@mixin cubicTransition($timing) {
  transition: all $timing cubic-bezier(0.770, 0.000, 0.175, 1.000);
}

@mixin niceGradient {
  background: #1CD8D2;
  background: linear-gradient(to right,
      #93EDC7,
      #1CD8D2);
}

@mixin niceGradientLeftDown {
  background: #93edc7;
  background: linear-gradient(135deg, #93edc7 0%, #1cd8d2 100%);
}